<template>
  <div :id="anchorName" class="auto-width-owl owl-has-nav owl--auto-width pt-5">
    <h3 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h3>
    <v-container class=" inner-container">
      <Owl :options="computedOptions" :refreshKey="refreshKey">
        <template v-slot:items>
          <div v-for="item in items" :key="item.id">
            <Item :item="item" />
          </div>
        </template>
      </Owl>
    </v-container>
  </div>
</template>

<script>
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
import Item from "./item.vue";
import owl from "../owl.js";
import ecvWaveCustomer from "@/components/wave/ecvWaveCustomer.vue";
export default {
  mixins: [owl, dynamicBlock],
  components: {
    Item,
    ecvWaveCustomer,
  },
  data: () => ({
    defaultOptions: {
      autoWidth: true,
      rewind: false,
      // responsive內設nav沒作用, 改用sass處理
      responsive: {
        0: {
          dots: false,
          margin: 15,
        },
        769: {
          dots: false,
          margin: 30,
        },
      },
    },
    refreshKey: 0,
  }),
  computed: {
    computedOptions() {
      let computedOptions = {
        ...this.defaultOptions,
        ...this.options,
      };
      return computedOptions;
    },
  },
};
</script>
