<template>
  <a :href="buttonUrl" :target="target" class="auto-width-item">
    <v-img
      :aspect-ratio="500/ 338"
      :src="photoUrl"
      :alt="photoName"
      class="mb-6 auto-width-item__img"
    ></v-img>
    <div class="px-3">
      <Main-btn outlined color="primary" class="mb-3 pointer-none tmw-14-15">{{
        subtitle
      }}</Main-btn>
      <p class="auto-width-item__title text-overflow-ep-2 tmw-17-20 ma-auto">
        {{ title }}
      </p>
    </div>
  </a>
</template>

<script>
import owlItemMixins from "@/components/owl/owlItem.js";
export default {
  mixins: [owlItemMixins],
  computed: {
    target() {
      return this.targetBlank ? "_blank" : "";
    },
  },
};
</script>